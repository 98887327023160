import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import "./Menu.css";

const Menu = () => {
  const [displayMenu, setShowHideMenu] = useState(false);

  const onMenuClickHandle = (evt) => {
    setShowHideMenu(!displayMenu);
  };

  useEffect(() => {
    return () => {
      const divs = document.querySelectorAll(".menu-item");
      divs.forEach((item) => {
        let word = item.children[0].children[0].innerText.split("");
        item.children[0].children[0].innerHTML = "";

        word.forEach((letter, idx) => {
          item.children[0].children[0].innerHTML += `<span style="--index: ${
            idx * 3
          };">${letter}</span>`;
        });

        let cloneDiv = item.children[0].cloneNode(true);
        cloneDiv.style.position = "absolute";
        cloneDiv.style.left = -100;
        cloneDiv.style.top = -100;
        item.appendChild(cloneDiv);
      });

      let item = document.querySelector(".menu-btn-effect");
      let word = item.children[0].innerText.split("");
      item.children[0].innerHTML = "";
      word.forEach((letter, idx) => {
        item.children[0].innerHTML += `<span style="--index: ${
          5 + idx * 5
        };">${letter}</span>`;
      });
    };
  }, []);
  return (
    <div>
      <div className={`site-name ${displayMenu ? "" : "menu-displayed"}`}>
        <div className="site-name-part1">Code</div>
        <div className="site-name-part2">Uniquely</div>
      </div>
      <div
        className={`menu-btn ${displayMenu ? "" : "menu-displayed"}`}
        onClick={onMenuClickHandle}
      >
        <div>M</div>
        <div className="menu-btn-effect">
          <span>ENU</span>
        </div>
      </div>

      <div className={`menu ${displayMenu ? "" : "hide-menu"}`}>
        <div className="menu_container">
          <a href="#work" className="menu-item" style={{ "--number": "6" }}>
            <div className="nav-txt-wrap">
              <div className="nav-90-div">
                <span className="menu-item-text">WORK</span>
              </div>
            </div>
          </a>
          <div className="nav-divider is-1st" style={{ "--number": "6" }}></div>
          <a href="#about" className="menu-item" style={{ "--number": "4" }}>
            <div className="nav-txt-wrap">
              <div className="nav-90-div">
                <span className="menu-item-text">ABOUT</span>
              </div>
            </div>
          </a>
          <div className="nav-divider is-1st" style={{ "--number": "4" }}></div>
          <a href="#contact" className="menu-item" style={{ "--number": "2" }}>
            <div className="nav-txt-wrap">
              <div className="nav-90-div">
                <span className="menu-item-text">CONTACT</span>
              </div>
            </div>
          </a>
          <div className="nav-divider is-1st" style={{ "--number": "2" }}></div>
          <div className="my-details-section">
            <div className="details-container">
              <div className="mobile">
                <a href="tel:+919552540363" className="txt-link">
                  +91 95525 40363
                </a>
              </div>
              <div className="email">
                <a
                  href="mailto:jomor@anand.sonu@gmail.com?subject=Hey Sunil!"
                  className="txt-link"
                >
                  anand.sonu@gmail.com
                </a>
              </div>
              <div className="social-details">
                <div className="social-icons">
                  <a
                    href="https://twitter.com/anand_sunil"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tilt-box-wrap"
                  >
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="tilt-box">
                      <strong>
                        <FontAwesomeIcon icon={faTwitter} />
                      </strong>
                    </div>
                  </a>

                  <a
                    href="https://www.linkedin.com/in/sunil-anand-ba19544/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tilt-box-wrap"
                  >
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="tilt-box">
                      <strong>
                        <FontAwesomeIcon icon={faLinkedin} />
                      </strong>
                    </div>
                  </a>
                  <a
                    href="https://github.com/your-github-username"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tilt-box-wrap"
                  >
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="tilt-box">
                      <strong>
                        <FontAwesomeIcon icon={faGithub} />
                      </strong>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="menu-bottom-img"></div>
      </div>
    </div>
  );
};

export default Menu;
