import React, { useEffect, useState, useRef } from 'react';
// import { gsap } from 'gsap';
import ThreeDodecahedron from './ThreeDodecahedron';
import './MathShapes.css';

const MathShapes = ({ position }) => {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0, z: 0 });

  const shapesRef = useRef(null);

  useEffect(() => {
    // Generate random values and set CSS variables for the shapes
    document.documentElement.style.setProperty('--random-1', Math.random());
    document.documentElement.style.setProperty('--random-2', Math.random());
    document.documentElement.style.setProperty('--random-3', Math.random());
    document.documentElement.style.setProperty('--random-4', Math.random());
    document.documentElement.style.setProperty('--random-5', Math.random());
    document.documentElement.style.setProperty('--random-6', Math.random());
    document.documentElement.style.setProperty('--random-7', Math.random());
    document.documentElement.style.setProperty('--random-8', Math.random());
    document.documentElement.style.setProperty('--random-9', Math.random());
    document.documentElement.style.setProperty('--random-10', Math.random());


    const handleScroll = () => {
      // const scrollY = window.scrollY;
      // gsap.to(shapesRef.current, { y: -scrollY * 0.2, duration: 0.5, ease: 'power3.out' });

      setScrollPosition({
        x: (window.scrollX-position.x) * 0.01, // Adjust the speed of movement
        y: (window.scrollY-position.y) * 0.01,
        z: 0,
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [position]);

  return (
    <div className="math-shapes" ref={shapesRef}>
        <ThreeDodecahedron position={scrollPosition} />
        {/* <div className="shape shape1 pyramid"></div>
        <div className="shape shape2 dodecahedron"></div>
        <div className="shape shape3 icosahedron"></div>
        <div className="shape shape4 pentagonal-prism"></div>
        <div className="shape shape5 octahedron"></div> */}
    </div>
  );
};

export default MathShapes;
