import React from 'react';
import './AnimatedShapes.css';

const AnimatedShapes = () => {
  return (
    <div className="animated-shapes-container">
      <div className="parallax shape shape1"></div>
      <div className="parallax shape shape2"></div>
      <div className="parallax shape shape3"></div>
    </div>
  );
}

export default AnimatedShapes;
