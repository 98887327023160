import React, { useEffect } from 'react';
import { motion, useScroll } from "framer-motion"
import Menu from "./components/Menu";
import Header from "./components/Header";
import AboutMe from "./components/AboutMe";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import AnimatedShapes from "./components/AnimatedShapes";
import { gsap } from "gsap";
import "./App.css";

function App() {
  const { scrollYProgress } = useScroll();

  useEffect(() => {
    const handleMousemove = (event) => {
      // Get the mouse coordinates relative to the window
      const mouseX = event.clientX;
      const mouseY = event.clientY;
  
      // Get the current scroll position
      const scrollX = window.scrollX;
      const scrollY = window.scrollY;
  
      // Adjust the mouse coordinates based on the scroll position
      const adjustedX = mouseX + scrollX;
      const adjustedY = mouseY + scrollY;
      
  
      // gsap.from(".hover_effect_cursor", {
      //   x: adjustedX,
      //   y: adjustedY,
      // });
  
      gsap.to(".hover_effect_shape", {
        x: adjustedX,
        y: adjustedY,
        stagger: 0.1,
      });
    };
    window.addEventListener("mousemove", handleMousemove);




    // const handleScroll = () => {
    //   const scrollPos = window.scrollY;
    //   const parallaxElements = document.querySelectorAll(".parallax");

    //   parallaxElements.forEach((element) => {
    //     const speed = element.getAttribute("data-speed");
    //     element.style.backgroundPositionY = -scrollPos * speed + "px";
    //   });
    // };

    

    // // window.addEventListener("scroll", handleScroll);
    
    // return () => window.removeEventListener("scroll", handleScroll);




    
  }, []);

  return (
    <div className="App">
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />  
      <div className='hover_effect'>
        <div className="hover_effect_cursor"></div>
        <div className="hover_effect_shapes"></div>
        <div className="hover_effect_shape hover_effect_shape-1"></div>
        <div className="hover_effect_shape hover_effect_shape-2"></div>
        <div className="hover_effect_shape hover_effect_shape-3"></div>
      </div>
      <div className="all-sections-content">
        
        
        <Menu />
        <Header />
        <div className="parallax section black" id="about-me" data-speed="0.5">
          <AnimatedShapes />
          <AboutMe />
        </div>
        <div className="parallax section yellow" id="projects" data-speed="0.7">
          <AnimatedShapes />
          <Projects />
        </div>
        <div className="parallax section red" id="skills" data-speed="0.6">
          <AnimatedShapes />
          <Skills />
        </div>
        <div className="parallax section yellow last-section" id="contact" data-speed="0.4">
          <AnimatedShapes />
          <Contact />
        </div>
        
      </div>
    </div>
  );
}

export default App;
