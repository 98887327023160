import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const ThreeDodecahedron = ({ position }) => {
  const canvasRef = useRef();
  const scene = useRef(null);
  const camera = useRef(null);
  const renderer = useRef(null);
  const dodecahedronMesh = useRef(null);

  useEffect(() => {
    scene.current = new THREE.Scene();
    camera.current = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    renderer.current = new THREE.WebGLRenderer({ canvas: canvasRef.current, alpha: true });
    renderer.current.setSize(window.innerWidth, window.innerHeight);
    renderer.current.setClearColor(0x000000, 0);

    const dodecahedronGeometry = new THREE.DodecahedronGeometry(3);
    const dodecahedronMaterial = new THREE.MeshBasicMaterial({ color: 0xdebd20, wireframe: true });
    dodecahedronMesh.current = new THREE.Mesh(dodecahedronGeometry, dodecahedronMaterial);
    scene.current.add(dodecahedronMesh.current);

    camera.current.position.z = 6;

    // dodecahedronMesh.current.position.x = 1

    const animate = () => {
      requestAnimationFrame(animate);
      dodecahedronMesh.current.rotation.x += 0.001;
      dodecahedronMesh.current.rotation.y += 0.001;
      renderer.current.render(scene.current, camera.current);
    };

    animate();

    return () => {
      scene.current.remove(dodecahedronMesh.current);
      dodecahedronGeometry.dispose();
      dodecahedronMaterial.dispose();
      renderer.current.dispose();
    };
  }, []);

  useEffect(() => {
    if (dodecahedronMesh.current) {
      dodecahedronMesh.current.position.set(position.x, position.y, position.z);
    }
  }, [position]);

  return <canvas ref={canvasRef} />;
};

export default ThreeDodecahedron;
