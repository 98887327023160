import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './AboutMe.css';

const AboutMe = () => {
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const descriptionRef1 = useRef(null);
  const descriptionRef2 = useRef(null);
  const descriptionRef3 = useRef(null);
  const descriptionRef4 = useRef(null);
  
  const aboutMeRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      gsap.to(titleRef.current, { y: -scrollY * 0.6, duration: 0.25, ease: 'power3.out' });
      gsap.to(descriptionRef1.current, { y: -scrollY * 0.5, duration: 0.3, ease: 'power3.out' });
      gsap.to(descriptionRef2.current, { y: -scrollY * 0.45, duration: 0.35, ease: 'power3.out' });
      gsap.to(descriptionRef3.current, { y: -scrollY * 0.35, duration: 0.4, ease: 'power3.out' });
      gsap.to(descriptionRef4.current, { y: -scrollY * 0.25, duration: 0.45, ease: 'power3.out' });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <section className="about-me" ref={aboutMeRef}>
      <div className="about-me-content">
        <h2 className="about-me-title" ref={titleRef}>About Me</h2>
        <div className='about-me-description' ref={descriptionRef}>
          <p className="about-me-text" ref={descriptionRef1}>
            Hi there! I'm Sunil Anand, a passionate and creative web developer with a strong focus on creating engaging and user-friendly experiences. With 20 plus years of experience in web development, I've worked on a variety of projects ranging from eLearning platforms to automation tools.
          </p>
          <p className="about-me-text" ref={descriptionRef2}>
            I specialize in developing responsive websites and web applications that comply with WCAG 2.1 AA standards, ensuring accessibility for all users. I am skilled in using React, JavaScript, and jQuery to build interactive and dynamic interfaces. My dedication to creating clean and efficient code allows me to create seamless user experiences across various devices and browsers.
          </p>
          <p className="about-me-text" ref={descriptionRef3}>
            When I'm not coding, you can find me exploring new technologies, learning new programming languages, or contributing to open-source projects. I love pushing the boundaries of what's possible on the web and bringing innovative ideas to life.
          </p>
          <p className="about-me-text" ref={descriptionRef4}>
            I'm excited about the future of web development and always eager to collaborate on challenging projects. Let's create something amazing together!
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
