import React, { useEffect, useState, useRef } from 'react';
import MathShapes from './shapes/MathShapes';
import './Skills.css';
// import MonsterTruckGame from './games/MonsterTruckGame';

const Skills = () => {
  const [sectionSrollPosition, setSectionSrollPosition] = useState({ x: 0, y: 0, z: 0 });
  const skillsSection = useRef(null);
  const skillsData = [
    {
      title: "Web Development",
      description: "Proficient in front-end and back-end development, creating interactive and responsive websites and web applications.",
    },
    {
      title: "JavaScript",
      description: "Expertise in JavaScript, ES6+ features, and modern frameworks like React for building dynamic user interfaces.",
    },
    {
      title: "WCAG 2.1 AA Compliance",
      description: "Experience in designing and developing websites and applications that adhere to web accessibility standards.",
    },
    {
      title: "Automation",
      description: "Automating repetitive tasks and workflows to improve efficiency using tools like Selenium and Puppeteer.",
    },
    {
      title: "Responsive Design",
      description: "Designing and developing websites that work seamlessly across various devices and screen sizes.",
    },
    {
      title: "Artificial Intelligence",
      description: "Familiarity with AI concepts and libraries like TensorFlow for creating intelligent applications.",
    },
    {
      title: "AR/VR Development",
      description: "Creating immersive augmented and virtual reality experiences using frameworks like A-Frame and Unity.",
    },
    {
      title: "Version Control",
      description: "Experience with version control systems like Git, enabling collaborative and efficient development.",
    },
  ];

  useEffect(() => {
    setSectionSrollPosition({
      x: skillsSection.current.offsetLeft,
      y: skillsSection.current.offsetTop,
      z: 0,
    });
  }, []);

  return (
    <section className="skills" ref={skillsSection}>
      <MathShapes position={sectionSrollPosition} />
      <div className="skills-content">
        <h2 className="skills-title">Skills</h2>
        <div className="skills-grid">
          {skillsData.map((skill, index) => (
            <div key={index} className="skill-box">
              <h3 className="skill-title">{skill.title}</h3>
              <p className="skill-description">{skill.description}</p>
              
            </div>
          ))}
        </div>
        {/* <MonsterTruckGame /> */}
      </div>
    </section>
  );
}

export default Skills;
