import { React, useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./Contact.css";
import "../fonts.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

const Contact = () => {
  const contactRef = useRef(null);
  const sectionRef = useRef(null);

  useEffect(() => {
    // Function to handle the GSAP animation
    const animateSection = () => {
      gsap.to(contactRef.current, { opacity: 1, x: 40, duration: 1 });
    };

    // Intersection Observer setup
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateSection(); // Trigger the GSAP animation when the section is in view
        }
      });
    });

    // Observe the section element
    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    // Cleanup the observer on unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section className="contact">
      <div className="contact-content">
        {/* <h2 className="contact-title">Contact</h2> */}
        {/* <p className="contact-description">
          Interested in working together or have a project idea in mind? Let's connect!
        </p> */}
        {/* <div className="contact-form">
          <input type="text" placeholder="Your Name" />
          <input type="email" placeholder="Your Email" />
          <textarea placeholder="Your Message"></textarea>
          <button className="contact-btn">Send Message</button>
        </div> */}
        <FontAwesomeIcon icon="fa-thin fa-handshake-simple" />
      </div>
      <div className="scrolling-contact-text" ref={contactRef}>
        <div className="side-text">CONTACT</div>
        <div className="center-text">ME</div>
        {/* <div className="side-text">CONTACT</div> */}
      </div>
      <div className="me-section">
        <div className="its-me">
          <img src="./images/transparent-me.png" alt="It's Me" />
        </div>
        <div className="me-section-right">
        <div className="my-details-section">
          <div className="details-container">
            <div className="details-left">
              <div className="mobile">
                <a href="tel:+919552540363" className="txt-link">
                  +91 95525 40363
                </a>
              </div>
              <div className="email">
                <a
                  href="mailto:jomor@anand.sonu@gmail.com?subject=Hey Sunil!"
                  className="txt-link"
                >
                  anand.sonu@gmail.com
                </a>
              </div>
            </div>
            <div className="details-right">
              <div className="social-details">
                <div className="social-icons">
                  <a
                    href="https://twitter.com/anand_sunil"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tilt-box-wrap"
                  >
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="tilt-box">
                      <strong>
                        <FontAwesomeIcon icon={faTwitter} />
                      </strong>
                    </div>
                  </a>

                  <a
                    href="https://www.linkedin.com/in/sunil-anand-ba19544/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tilt-box-wrap"
                  >
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="tilt-box">
                      <strong>
                        <FontAwesomeIcon icon={faLinkedin} />
                      </strong>
                    </div>
                  </a>
                  <a
                    href="https://github.com/your-github-username"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tilt-box-wrap"
                  >
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="t_over"></div>
                    <div className="tilt-box">
                      <strong>
                        <FontAwesomeIcon icon={faGithub} />
                      </strong>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="locate-me">
          <h2>Where to look for me?</h2>
          <div>H2-902, Park Infinia, Fursungi - Pune, Maharashtra, India</div>
        </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
