import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <h1 className="header-title">Sunil</h1>
        <p className="header-subtitle">Web Developer | Digital Creator</p>
      </div>
    </header>
  );
}

export default Header;
