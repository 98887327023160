import React from 'react';
import './Projects.css';

const Projects = () => {
  const projectsData = [
    {
      title: "Math Genius App",
      description: "A fun and educational mobile app that helps users improve their math skills through interactive quizzes and challenges.",
      category: "Math",
      icon: "🧮",
    },
    {
      title: "Astrology Horoscope Generator",
      description: "An astrology web application that generates personalized horoscopes based on the user's birth date and time.",
      category: "Astrology",
      icon: "🌠",
    },
    // Add more project objects for other categories
  ];

  return (
    <section className="projects">
      <div className="projects-content">
        <h2 className="projects-title">Projects</h2>
        <div className="projects-grid">
          {projectsData.map((project, index) => (
            <div key={index} className="project-box">
              <div className="project-icon">{project.icon}</div>
              <h3 className="project-title">{project.title}</h3>
              <p className="project-description">{project.description}</p>
              <span className="project-category">{project.category}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Projects;
